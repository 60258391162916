<template>
    <MembershipTypes unitType="SingleSession" />
</template>

<script>
import MembershipTypes from "@/components/Admin/MembershipType/MembershipTypes"

export default {
  components: {
    MembershipTypes,
  },
  data() {
    return {};
  },
};
</script>